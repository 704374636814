/*
Template Name: Adminto - Responsive Bootstrap 4 Admin Dashboard
Author: CoderThemes
Website: https://coderthemes.com/
Contact: support@coderthemes.com
File: Main Js File
*/


!function ($) {
    "use strict";

    var Components = function () { };

    //initializing tooltip
    Components.prototype.initTooltipPlugin = function () {
        $.fn.tooltip && $('[data-toggle="tooltip"]').tooltip()
    },

    //initializing popover
    Components.prototype.initPopoverPlugin = function () {
        $.fn.popover && $('[data-toggle="popover"]').popover()
    },

    //initializing Slimscroll
    Components.prototype.initSlimScrollPlugin = function () {
        //You can change the color of scroll bar here
        $.fn.slimScroll && $(".slimscroll").slimScroll({
            height: 'auto',
            position: 'right',
            size: "8px",
            touchScrollStep: 20,
            color: '#9ea5ab'
        });
    },

    //initializing form validation
    Components.prototype.initFormValidation = function () {
        $(".needs-validation").on('submit', function (event) {
            $(this).addClass('was-validated');
            if ($(this)[0].checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
                return false;
            }
            return true;
        });
    },

    //initializing custom modal
    Components.prototype.initCustomModalPlugin = function() {
        $('[data-plugin="custommodal"]').on('click', function(e) {
            e.preventDefault();
            var modal = new Custombox.modal({
                content: {
                    target: $(this).attr("href"),
                    effect: $(this).attr("data-animation")
                },
                overlay: {
                    color: $(this).attr("data-overlayColor")
                }
            });
            // Open
            modal.open();
        });

        document.addEventListener('custombox:overlay:complete', function() {
            $('body').trigger('ajaxInit');
        });
    },

    // Counterup
    Components.prototype.initCounterUp = function() {
        var delay = $(this).attr('data-delay')?$(this).attr('data-delay'):100; //default is 100
        var time = $(this).attr('data-time')?$(this).attr('data-time'):1200; //default is 1200
         $('[data-plugin="counterup"]').each(function(idx, obj) {
            $(this).counterUp({
                delay: 100,
                time: 1200
            });
         });
    },

    //peity charts
    Components.prototype.initPeityCharts = function() {
        $('[data-plugin="peity-pie"]').each(function(idx, obj) {
            var colors = $(this).attr('data-colors')?$(this).attr('data-colors').split(","):[];
            var width = $(this).attr('data-width')?$(this).attr('data-width'):20; //default is 20
            var height = $(this).attr('data-height')?$(this).attr('data-height'):20; //default is 20
            $(this).peity("pie", {
                fill: colors,
                width: width,
                height: height
            });
        });
        //donut
         $('[data-plugin="peity-donut"]').each(function(idx, obj) {
            var colors = $(this).attr('data-colors')?$(this).attr('data-colors').split(","):[];
            var width = $(this).attr('data-width')?$(this).attr('data-width'):20; //default is 20
            var height = $(this).attr('data-height')?$(this).attr('data-height'):20; //default is 20
            $(this).peity("donut", {
                fill: colors,
                width: width,
                height: height
            });
        });

        $('[data-plugin="peity-donut-alt"]').each(function(idx, obj) {
            $(this).peity("donut");
        });

        // line
        $('[data-plugin="peity-line"]').each(function(idx, obj) {
            $(this).peity("line", $(this).data());
        });

        // bar
        $('[data-plugin="peity-bar"]').each(function(idx, obj) {
            var colors = $(this).attr('data-colors')?$(this).attr('data-colors').split(","):[];
            var width = $(this).attr('data-width')?$(this).attr('data-width'):20; //default is 20
            var height = $(this).attr('data-height')?$(this).attr('data-height'):20; //default is 20
            $(this).peity("bar", {
                fill: colors,
                width: width,
                height: height
            });
         });
    },

    Components.prototype.initKnob = function() {
        $('[data-plugin="knob"]').each(function(idx, obj) {
           $(this).knob();
        });
    },

    Components.prototype.initCopyClipboard = function() {
        $('[data-copy]').each(function(idx, obj) {
            $(obj).on('click', function (event) {
                event.preventDefault();

                var target = $(event.target);

                if (!target.is('a')) {
                    target = target.closest('a[data-copy]');
                }

                if (typeof navigator.clipboard != 'undefined') {
                    navigator.clipboard.writeText(target.data('copy'));

                    if (target.data('message')) {
                        alert(target.data('message'));
                    }
                } else {
                    alert('Error copiando en el portapapeles.');
                }                
            });
        });
    },

    Components.prototype.initTippyTooltips = function () {
        if($('[data-plugin="tippy"]').length > 0)
        tippy('[data-plugin="tippy"]');
    },

    Components.prototype.initCustomInput = function () {
        $(".custom-file-input").on("change", function() {
            var fileName = $(this).val().split("\\").pop();
            $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
        });
    },

    Components.prototype.initDataControl = function () {
        var changeControl = function (event) {
            var select = $(event.target);

            changeControlRegular(select);
            changeControlInverse(select);
            changeControlMessage(select);
        };

        var changeControlMessage = function (select) {
            var formMessage = $('[data-message-by*="' + select.attr('id') + '"]');

            if ("1" == select.val()) {
                formMessage.hide();
            } else {
                formMessage.show();
            }
        };

        var changeControlRegular = function (select) {
            var fields = $('[data-control-by*="' + select.attr('id') + '"]');

            if (fields.length > 0) {
                for (var i = 0; i<fields.length; i++) {
                    var formGroup = $(fields[i]).closest('.form-group');
            
                    if ("1" == select.val()) {
                        showGroup(formGroup, select.attr('id'));
                    } else {
                        hideGroup(formGroup, select.attr('id'));
                    }
                }
            }
        };

        var changeControlInverse = function (select) {
            var fields = $('[data-control-inverse-by*="' + select.attr('id') + '"]');

            if (fields.length > 0) {
                for (var i = 0; i<fields.length; i++) {
                    var inverseGroup = $(fields[i]).closest('.form-group');
            
                    if ("1" == select.val()) {
                        hideGroup(inverseGroup, select.attr('id'));
                    } else {
                        showGroup(inverseGroup, select.attr('id'));
                    }
                }
            }
        };

        var hideGroup = function (formGroup, byId) {
            if (false == formGroup.is('.hide-by-' + byId)) {
                formGroup.addClass('hide-by-' + byId);
            }
            
            formGroup.hide();
        };

        var showGroup = function (formGroup, byId) {
            formGroup.removeClass('hide-by-' + byId);

            if (false == formGroup.is('[class*="hide-by-"]')) {
                formGroup.show();
            }
        };

        $('body').on('change.in.chain', 'select[data-control="data-control"]', function (event) {
            var select = $(event.target);

            if (select.is(':visible')) {
                changeControl(event);
            } else {
                if ("1" == select.val()) {
                    changeControlInverse(select);
                } else {
                    changeControlRegular(select);                    
                }
            }
        });

        $('body').on('change', 'select[data-control="data-control"]', function (event) {
            var select = $(event.target);

            changeControl(event);

            //Lanzamos los triggers de todos los elementos internos
            $('select[data-control-by*="' + $(event.target).attr('id') + '"][data-control="data-control"]').trigger('change.in.chain');
            $('select[data-control-inverse-by*="' + $(event.target).attr('id') + '"][data-control="data-control"]').trigger('change.in.chain');
        });

        $('select[data-control="data-control"]:not([data-control-by])').trigger('change');
    },

    Components.prototype.initSummernote = function () {
        var editors = $('.summernote-editor:not(.summernote-active)');

        if (editors.length > 0) {
            for (var i=0; i<editors.length; i++) {
                var editor = $(editors[i]);

                editor.addClass('summernote-active');
                editor.summernote({
                    lang: 'es-ES',
                    popover: {
                        image: [
                            ['custom', ['imageAttributes']],
                            ['imagesize', ['imageSize100', 'imageSize50', 'imageSize25']],
                            ['float', ['floatLeft', 'floatRight', 'floatNone']],
                            ['remove', ['removeMedia']]
                        ],
                        link: [
                            ['link', ['linkDialogShow', 'unlink']]
                        ]
                    },
                    toolbar: [
                        ['history', ['undo', 'redo']],
                        ['style', ['style', 'bold', 'italic', 'underline', 'clear']],
                        ['fontsize', ['fontsize']],
                        ['height', ['height']],
                        ['color', ['color']],
                        ['paragraph', ['ul', 'ol', 'paragraph']],
                        ['table', ['table']],
                        ['func', ['fullscreen', 'codeview']]
                    ],
                    icons: {
                        align:"fa fa-align",
                        alignCenter:"fa fa-align-center",
                        alignJustify:"fa fa-align-justify",
                        alignLeft:"fa fa-align-left",
                        alignRight:"fa fa-align-right",
                        rowBelow:"fa fa-row-below",
                        colBefore:"fa fa-col-before",
                        colAfter:"fa fa-col-after",
                        rowAbove:"fa fa-row-above",
                        rowRemove:"fa fa-row-remove",
                        colRemove:"fa fa-col-remove",
                        indent:"fa fa-indent",
                        outdent:"fa fa-outdent",
                        arrowsAlt:"fa fa-arrows-alt",
                        bold:"fa fa-bold",
                        caret:"fa fa-caret-down",
                        circle:"fa fa-circle",
                        close:"fa fa-close",
                        code:"fa fa-code",
                        eraser:"fa fa-eraser",
                        font:"fa fa-font",
                        frame:"fa fa-frame",
                        italic:"fa fa-italic",
                        link:"fa fa-link",
                        unlink:"fa fa-chain-broken",
                        magic:"fa fa-magic",
                        menuCheck:"fa fa-menu-check",
                        minus:"fa fa-minus",
                        orderedlist:"fa fa-list-ol",
                        pencil:"fa fa-pencil",
                        picture:"fa fa-file-text",
                        question:"fa fa-question",
                        redo:"fa fa-redo",
                        square:"fa fa-square",
                        strikethrough:"fa fa-strikethrough",
                        subscript:"fa fa-subscript",
                        superscript:"fa fa-superscript",
                        table:"fa fa-table",
                        textHeight:"fa fa-text-height",
                        trash:"fa fa-trash",
                        underline:"fa fa-underline",
                        undo:"fa fa-undo",
                        unorderedlist:"fa fa-list-ul",
                        video:"fa fa-video-camera"
                    },  
                    imageAttributes:{
                        imageDialogLayout:'default', // default|horizontal
                        icon:'<i class="fa fa-pencil"/>',
                        removeEmpty:false // true = remove attributes | false = leave empty if present
                    },
                    displayFields:{
                        imageBasic: true,  // show/hide Title, Source, Alt fields
                        imageExtra: false, // show/hide Alt, Class, Style, Role fields
                        linkBasic: true,   // show/hide URL and Target fields for link
                        linkExtra: false   // show/hide Class, Rel, Role fields for link
                    }
                });
            }
        }
    },

    //Collection
    Components.prototype.jsCollection = function () {
        var collections = $('.js-collection:not(.js-collection-enabled)');

        if (collections.length > 0) {
            for (var i=0; i<collections.length; i++) {
                var collection = $(collections[i]);

                collection.on('click', '.add-field', function (event) {
                    event.preventDefault();

                    var collection = $(event.delegateTarget);

                    var table = collection.find('.js-collection-table');
                    var index = table.data('index');

                    var html = table.data('prototype').replace(/__name__/g, index);

                    table.data('index', index + 1);
                    table.find('tbody').append(html);

                    table.find('tr.no-lines').remove();

                    table.trigger('new-elements');

                    $.Components.ajaxInit();
                });

                collection.on('click', '.remove-field', function (event) {
                    event.preventDefault();
                    
                    $(event.target).closest('tr').remove();

                    var table = collection.find('.js-collection-table');
                    table.trigger('remove-elements');
                });

                collection.addClass('js-collection-enabled');
            }
        }
    },

    Components.prototype.jsDynamicCollection = function () {
        var collections = $('.js-dynamic-collection:not(.js-collection-enabled)');

        if (collections.length > 0) {
            for (var i=0; i<collections.length; i++) {
                var collection = $(collections[i]);

                var collectionContainer = $(collection.data('container'));

                if (collectionContainer) {
                    collection.on('click', '.add-field', function (event) {
                        event.preventDefault();

                        var link = $(event.target);
                        if (!link.is('.add-field')) {
                            link = link.closest('.add-field');
                        }

                        var addElementUrl = link.data('add-element-url');

                        var collectionContainer = $(link.closest('.js-dynamic-collection').data('container'));
                        
                        $.ajax({
                            'url': addElementUrl,
                            'type': 'post',
                            'collectionContainer': collectionContainer,
                            'data': collectionContainer.find('form').serialize(),
                            'success': function (html) {
                                collectionContainer = $(this.containerSelector);

                                this.collectionContainer.html(html);

                                this.collectionContainer.trigger('new-elements');
                
                                $.Components.ajaxInit();
                            }
                        });
                    });

                    collection.on('click', '.remove-field', function (event) {
                        event.preventDefault();
                        
                        $(event.target).closest('tr').remove();
    
                        var table = collection.find('.js-collection-table');
                        table.trigger('remove-elements');
                    });
                }                

                collection.addClass('js-collection-enabled');
            }
        }
    } 

    //Input And Window
    Components.prototype.inputAndWindow = function () {
        var inputAndWindowQuestions = $('.input-and-window:not(.input-and-window-enabled)');

        if (inputAndWindowQuestions.length > 0) {
            for (var i=0; i<inputAndWindowQuestions.length; i++) {
                var inputAndWindow = $(inputAndWindowQuestions[i]);
                var modalWindow = inputAndWindow.closest('.input-group').next();
                var link = inputAndWindow.closest('.input-group').find('.input-button');
                
                link.on('click', function (event) {
                    event.preventDefault();

                    var link = $(event.target);
                    if (!link.is('.input-button')) {
                        link = link.closest('.input-button');
                    }

                    var modalWindow = link.closest('.input-group').next();

                    modalWindow.modal('show');

                    $.ajax({
                        'url': link.data('href'),
                        'success': function (html) {
                            modalWindow.find('.modal-body').html(html);

                            $.Components.ajaxInit();
                        },
                        'error': function (html) {
                            modalWindow.find('.modal-body').html(html);
                        }
                    });

                    return false;
                });

                inputAndWindow.on('change', function (event) {
                    var input = $(event.target);
                    
                    input.next().html(input.val());
                });

                inputAndWindowQuestions.addClass('input-and-window-enabled');
            }
        }
    },

    //Bar Chart
    Components.prototype.barChart = function () {
        var barCharts = $('.bar-chart:not(.bar-chart-enabled)');

        if (barCharts.length > 0) {
            for (var i=0; i<barCharts.length; i++) {
                var barChart = $(barCharts[i]);

                var barChartConfig = eval(barChart.data('chart'));
                var ctx = barChart;
                new Chart(ctx, barChartConfig);

                barChart.addClass('bar-chart-enabled');
            }
        }
    },

    Components.prototype.ajaxModal = function () {
        var ajaxButtons = $('.ajax-modal:not(.ajax-modal-loaded');

        if (ajaxButtons.length > 0) {
            for(var i=0; i<ajaxButtons.length; i++) {
                var ajaxButton = $(ajaxButtons[i]);

                ajaxButton.on('click', function (event) {
                    event.preventDefault();

                    var link = $(event.target);

                    if (!link.is('a')) {
                        link = link.closest('a');
                    }

                    $.ajax({
                        url: link.attr('href'),
                        success: function (html) {
                            var modal = $(link.data('target'));

                            modal.find('.modal-body').html(html);

                            modal.modal('show');
                        }
                    })

                    return false;
                });

                ajaxButton.addClass('ajax-modal-loaded');
            }
        }
    },

    Components.prototype.toggleClass = function () {
        var toggleButtons = $('[data-toggle="class"]:not(.toggle-class-loaded');

        if (toggleButtons.length > 0) {
            for(var i=0; i<toggleButtons.length; i++) {
                var toggleButton = $(toggleButtons[i]);

                toggleButton.on('click', function (event) {
                    event.preventDefault();

                    var link = $(event.target);

                    if (!link.is('[data-toggle="class"]')) {
                        link = link.closest('[data-toggle="class"]');
                    }

                    var target = $(link.data('target'));
                    var string_class = link.data('class');
                    var target_input = $(link.data('target-input'));

                    if (target.hasClass(string_class)) {
                        link.removeClass('active');
                        target.removeClass(string_class);

                        if (target_input) {
                            target_input.attr('checked', null);
                        }

                        target.trigger('remove-class');
                    } else {
                        target.addClass(string_class);
                        link.addClass('active');

                        if (target_input) {
                            target_input.attr('checked', 'checked');
                        }

                        target.trigger('add-class');
                    }

                    return false;
                });

                toggleButton.addClass('toggle-class-loaded');
            }
        }
    },

    Components.prototype.initBusinessLineSelector = function () {
        $('body').on('change', '#selector_businessLine, #selector-action_businessLine', function (event) {
            var select = $(event.target);
            var form = select.closest('form');

            $.ajax({
                'url': form.attr('action'),
                'data': form.serialize(),
                'type': 'POST',
                'success': function () {
                    if (select.is('#selector_businessLine')) {
                        window.location.reload();
                    } else {
                        select.trigger('businessLineSelector.success');
                    }
                },
                'error': function () {
                    console.log('Error desconocido');
                }
            });
        });
    },

    Components.prototype.initEvidences = function () {
        $('body').on('change', '.evidences input[type="file"]', function (event) {
            var input = $(event.target);

            var formData = new FormData;
            formData.append("file", input.prop('files')[0]);
            
            var request = new XMLHttpRequest();
            request.open("POST", input.data('target'));
            request.onload = function () {
                input.closest('.evidences').replaceWith(this.responseText);

                var counter = $($('.evidences').data('counter'));
                counter.html('(' + $('.evidences .delete-evidence').length + ')');
                counter.data('count', $('.evidences .delete-evidence').length);

                $('body').trigger('ajaxInit');
            };
            request.send(formData);
        });

        $('body').on('click', '.evidences .delete-evidence', function (event) {
            event.preventDefault();

            var link = $(event.target);

            if (!link.is('a')) {
                link = link.closest('a');
            }

            //Si es necesario mostramos mensaje de confirmación
            if (link.data('confirm')) {
                if (!confirm(link.data('confirm'))) {
                    return false;
                }
            }

            $.ajax({
                url: link.attr('href'),
                success: function (html) {
                    link.closest('.evidences').replaceWith(html);
                    
                    var counter = $($('.evidences').data('counter'));
                    counter.html('(' + $('.evidences .delete-evidence').length + ')');
                    counter.data('count', $('.evidences .delete-evidence').length);

                    $('body').trigger('ajaxInit');
                }
            })
        });
    },

    //initilizing
    Components.prototype.init = function () {
        var $this = this;
        this.initTooltipPlugin(),
        this.initPopoverPlugin(),
        this.initSlimScrollPlugin(),
        this.initFormValidation(),
        this.initCustomModalPlugin(),
        this.initCounterUp(),
        this.initPeityCharts(),
        this.initKnob(),
        this.initCopyClipboard(),
        this.initTippyTooltips(),
        this.initDataControl(),
        this.initCustomInput(),
        this.initSummernote(),
        this.jsCollection(),
        this.jsDynamicCollection(),
        this.inputAndWindow(),
        this.ajaxModal(),
        this.toggleClass(),
        this.barChart(),
        this.initBusinessLineSelector(),
        this.initEvidences();
    },

    //Ajax initilizing
    Components.prototype.ajaxInit = function () {
        var $this = this;
        this.initCustomInput(),
        this.initSummernote(),
        this.ajaxModal(),
        this.toggleClass(),
        this.jsCollection(),
        this.jsDynamicCollection();

        $('body').trigger('ajaxInit');
    },

    $.Components = new Components, $.Components.Constructor = Components

}(window.jQuery),

function($) {
    "use strict";

    /**
    Portlet Widget
    */
    var Portlet = function() {
        this.$body = $("body"),
        this.$portletIdentifier = ".card",
        this.$portletCloser = '.card a[data-toggle="remove"]',
        this.$portletRefresher = '.card a[data-toggle="reload"]'
    };

    //on init
    Portlet.prototype.init = function() {
        // Panel closest
        var $this = this;
        $(document).on("click",this.$portletCloser, function (ev) {
            ev.preventDefault();
            var $portlet = $(this).closest($this.$portletIdentifier);
                var $portlet_parent = $portlet.parent();
            $portlet.remove();
            if ($portlet_parent.children().length == 0) {
                $portlet_parent.remove();
            }
        });

        // Panel Reload
        $(document).on("click",this.$portletRefresher, function (ev) {
            ev.preventDefault();
            var $portlet = $(this).closest($this.$portletIdentifier);
            // This is just a simulation, nothing is going to be reloaded
            $portlet.append('<div class="card-disabled"><div class="card-portlets-loader"></div></div>');
            var $pd = $portlet.find('.card-disabled');
            setTimeout(function () {
                $pd.fadeOut('fast', function () {
                    $pd.remove();
                });
            }, 500 + 300 * (Math.random() * 5));
        });
    },
    //
    $.Portlet = new Portlet, $.Portlet.Constructor = Portlet
    
}(window.jQuery),

function ($) {
    'use strict';

    var App = function () {
        this.$body = $('body'),
        this.$window = $(window)
    };

    /**
    Resets the scroll
    */
    App.prototype._resetSidebarScroll = function () {
        // sidebar - scroll container
        $('.slimscroll-menu').slimscroll({
            height: 'auto',
            position: 'right',
            size: "8px",
            color: '#9ea5ab',
            wheelStep: 5,
            touchScrollStep: 20
        });
    },

    /** 
     * Initlizes the menu - top and sidebar
    */
    App.prototype.initMenu = function () {
        var $this = this;

        // Left menu collapse
        $('.button-menu-mobile').on('click', function (event) {
            event.preventDefault();
            $this.$body.toggleClass('sidebar-enable');
            if ($this.$window.width() >= 768) {
                $this.$body.toggleClass('enlarged');
            } else {
                $this.$body.removeClass('enlarged');
            }

            // sidebar - scroll container
            $this._resetSidebarScroll();
        });

        // sidebar - main menu
        $("#side-menu").metisMenu();

        // sidebar - scroll container
        $this._resetSidebarScroll();

        // right side-bar toggle
        $('.right-bar-toggle').on('click', function (e) {
            $('body').toggleClass('right-bar-enabled');
        });

        $(document).on('click', 'body', function (e) {
            if ($(e.target).closest('.right-bar-toggle, .right-bar').length > 0) {
                return;
            }

            if ($(e.target).closest('.left-side-menu, .side-nav').length > 0 || $(e.target).hasClass('button-menu-mobile')
                || $(e.target).closest('.button-menu-mobile').length > 0) {
                return;
            }

            // $('body').removeClass('right-bar-enabled');
            // $('body').removeClass('sidebar-enable');
            return;
        });

        // activate the menu in left side bar based on url
        $("#side-menu a").each(function () {
            var pageUrl = window.location.href.split(/[?#]/)[0];
            if (this.href == pageUrl) {
                $(this).addClass("active");
                $(this).parent().addClass("active"); // add active to li of the current link
                $(this).parent().parent().addClass("in");
                $(this).parent().parent().prev().addClass("active"); // add active class to an anchor
                $(this).parent().parent().parent().addClass("active");
                $(this).parent().parent().parent().parent().addClass("in"); // add active to li of the current link
                $(this).parent().parent().parent().parent().parent().addClass("active");
            }
        });

        // Topbar - main menu
        $('.navbar-toggle').on('click', function (event) {
            $(this).toggleClass('open');
            $('#navigation').slideToggle(400);
        });

        // Preloader
        $(window).on('load', function () {
            $('#status').fadeOut();
            $('#preloader').delay(350).fadeOut('slow');
        });
    },

    /** 
     * Init the layout - with broad sidebar or compact side bar
    */
    App.prototype.initLayout = function () {
        // in case of small size, add class enlarge to have minimal menu
        if (this.$window.width() >= 768 && this.$window.width() <= 1028) {
            this.$body.addClass('enlarged');
        } else {
            if (this.$body.data('keep-enlarged') != true) {
                this.$body.removeClass('enlarged');
            }
        }
    },

    //initilizing
    App.prototype.init = function () {
        var $this = this;
        this.initLayout();
        $.Portlet.init();
        this.initMenu();
        $.Components.init();
        // on window resize, make menu flipped automatically
        $this.$window.on('resize', function (e) {
            e.preventDefault();
            $this.initLayout();
            $this._resetSidebarScroll();
        });
    },

    $.App = new App, $.App.Constructor = App


}(window.jQuery),
//initializing main application module
function ($) {
    "use strict";
    $.App.init();
}(window.jQuery);

// Waves Effect
Waves.init();